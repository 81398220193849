import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { APP_CONST } from "../config/const";
const PublicRoutes = ({ isAuth, redirectPath = "/home", children }) => {
  const { appDetails } = useSelector((state) => state.app);
  // if (
  //   appDetails?.BRAND_STATUS !== null &&
  //   appDetails?.BRAND_STATUS !== APP_CONST.ORGANISATION_STATUS.Live
  // ) {
  //   return <Navigate to={"/maintenance"} replace />;
  // }
  if (isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
export default PublicRoutes;
