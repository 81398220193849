import React, { Suspense, useEffect } from "react";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./ScrollToTop";
import { APP_CONST } from "./config/const";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import LoaderImg from "./assets/images/loader.gif";
const Footer = React.lazy(() => import("./containers/Footer"));

const PaytmWithdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdrawNew/PaytmWithdraw")
);
const BkashWitdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdrawNew/BkashWitdraw")
);
const NagadWithdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdrawNew/NagadWithdraw")
);
const Bkash = React.lazy(() =>
  import("./views/pages/privatePages/deposit/Bkash")
);
const Nagad = React.lazy(() =>
  import("./views/pages/privatePages/deposit/Nagad")
);
const Paytm = React.lazy(() =>
  import("./views/pages/privatePages/deposit/Paytm")
);

const PublicSportsBook = React.lazy(() =>
  import("./views/pages/privatePages/sportsBook")
);
const ContactUs = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/ContactUs")
);
const PrivacyPolicy = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/PrivacyPolicy")
);
const ResponsibleGame = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/ResponsibleGame")
);
const FairPlay = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/FairPlay")
);
const GamesRule = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/GamesRule")
);
const Terms = React.lazy(() =>
  import("./views/pages/privatePages/FooterPages/Terms")
);
const BeforeLoginFooter = React.lazy(() =>
  import("./containers/BeforeLoginFooter")
);
const BeforeContactUs = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeContactUs")
);
const BeforeFairPlay = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeFairPlay")
);
const BeforeGamesRule = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeGamesRule")
);
const BeforePrivacyPolicy = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforePrivacyPolicy")
);
const BeforeResponsibleGame = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeResponsibleGame")
);
const BeforeTerms = React.lazy(() =>
  import("./views/pages/publicPages/FooterPages/BeforeTerms")
);
const TestSportsBook = React.lazy(() =>
  import("./views/pages/privatePages/sportsBook/testSportsBook")
);
const Bonus = React.lazy(() => import("./views/pages/privatePages/bonus"));
const BonusCard = React.lazy(() =>
  import("./views/pages/privatePages/bonus/bonusCard")
);
const Referral = React.lazy(() =>
  import("./views/pages/privatePages/referral")
);
const GatewaysList = React.lazy(() =>
  import("./views/pages/privatePages/deposit/gatewaysList")
);
const DepositGateway = React.lazy(() =>
  import("./views/pages/privatePages/deposit/depositGateway")
);
const WithDrawGatewaysList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/withdrawGatewaysList")
);
const WithdrawGateway = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/withdrawGateway")
);
const PlayerBankDetailsForm = React.lazy(() =>
  import("./views/pages/privatePages/playerBankDetails")
);
const CustomSports = React.lazy(() =>
  import("./views/pages/privatePages/customSports")
);
const CasinoProviderGames = React.lazy(() =>
  import("./containers/CasinoProviderGames")
);
const Slots = React.lazy(() => import("./views/pages/privatePages/Slots"));
const Aura = React.lazy(() => import("./views/pages/privatePages/aura"));
const WithdrawNew = React.lazy(() =>
  import("./views/pages/privatePages/withdrawNew")
);
const TronConfirm = React.lazy(() =>
  import("./views/pages/privatePages/deposit/TronConfirm")
);
const TronConfirmWithdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdrawNew/TronConfirmWithdraw")
);
const Maintenance = React.lazy(() =>
  import("./views/pages/publicPages/Maintenance")
);
const ExchangeSPMSportsLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeSPMSportsLobbyPage")
);
const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);
const JetFairLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/jetFairLobby")
);

const WithdrawGateways = React.lazy(() =>
  import("./views/pages/privatePages/withdrawNew/Gateways")
);
const DepositGateways = React.lazy(() =>
  import("./views/pages/privatePages/deposit/gateways")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
  }, []);

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    dispatch(getAppDetails());
    // Initialize on mount
    setVhUnit();
    // Add event listener for resize
    window.addEventListener("resize", setVhUnit);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []); // Empty dependency array ensures that this effect runs once on mount

  return (
    <Router>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="application/ld+json">
{
	"@context": "http://schema.org/",
	"@type": "WebSite",
	"url": " https://www.daddy365.com ",
	"potentialAction": {
		"@type": "SearchAction",
		"target": "{search_term_string}",
		"query-input": "required name=search_term_string"
	}
}
</script>`,
        }}
      ></div>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
      />
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="LoaderImg">
            <img src={LoaderImg} />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <BeforeLoginFooter />
              </PublicRoutes>
            }
          />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/aura-games"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Aura />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/spmgames"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <CustomSports />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <Footer />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawGateways"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithDrawGatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />

          <Route
            path="/withdrawGateway/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawGateway />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbook/:category"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="sports/:category"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </PublicRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Betby />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                {/* <Footer /> */}
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="test" element={<Sports />} /> */}
          <Route
            path="/spmsports/:category"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeSPMSportsLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            // path="/sports/exchange"
            path="/exchange/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <>
                <MatchDetailPage isAuth={isAuth} />
                {isAuth ? <Footer /> : <BeforeLoginFooter />}
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ContactUs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/responsiblegame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGame />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GamesRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/terms"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Terms />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="/contactus" element={<ContactUs />} /> */}
          <Route
            path="/contactuss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeContactUs />
              </PublicRoutes>
            }
          />
          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePrivacyPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />
          <Route
            path="/responsiblegames"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsibleGame />
              </PublicRoutes>
            }
          />
          <Route
            path="/gamesrules"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeGamesRule />
              </PublicRoutes>
            }
          />
          <Route
            path="/termss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeTerms />
              </PublicRoutes>
            }
          />
          <Route
            path="/sportsbooktest"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TestSportsBook />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/bank-details"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PlayerBankDetailsForm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/jetfair"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <JetFairLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route path="/:providerCode" element={<CasinoProviderGames />} />

          <Route
            path="/withdrawnew"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawNew />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bkash"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bkash />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/paytm"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Paytm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/bkashwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BkashWitdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/paytmwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PaytmWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/nagad"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Nagad />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/nagadwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <NagadWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit/:mode"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateways />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/withdraw/:mode"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawGateways />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/TronConfirm"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TronConfirm />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/TronConfirmWithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TronConfirmWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/paytmwithdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PaytmWithdraw />
              </ProtectedRoutes>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
